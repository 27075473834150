import React from "react"; 
const styles = require("../styles/appDetail.module.css");
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

const Testers = ({data, handleAddTester, handleDelTester}) => {
    return (
        <div className = {styles.cls_appDetTestersWrapper}>
            <div className = {styles.cls_overviewPart1}>
                <div className = {styles.cls_overViewAPIKeyHead}>
                    <div className = {styles.cls_overviewTitle}>Testers</div>
                    <div className = {styles.cls_overviewBtnWrap}>
                        <div className = {styles.cls_overviewAddAPIBtn} onClick = {() => handleAddTester()}>
                            Add Testers
                        </div>
                    </div>
                </div>
                {
                    data && data.length == 0 &&
                    <div className = {styles.noApiKeysText}> No Testers. Add one </div>
                }
                {
                    data.length > 0 &&
                    <TableContainer component={Paper} className = {styles.cls_apiTableCont}>
                        <div className={styles.cls_apiTableWrapper}>
                            <Table  aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell component="th" scope="row">
                                    {row.email}
                                    </TableCell>
                                    <TableCell align="left">{row.state}</TableCell>
                                    <TableCell align="right"><div className = {styles.revokeAccessBtn} onClick = {() => handleDelTester(row.tester_invite_id)}>Delete Access</div></TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                }
            </div>
        </div>
    )
}

export default Testers;