import TabPanel from "../TabPanel";
import React, { useEffect, useState } from "react"; 
import { API_URL } from "../../constants/constants";
const styles = require("../../styles/appDetail.module.css");
import { toast } from "react-toastify";
import Overview from "../Overview";
import { makeStyles, Tab, Tabs, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField , FormControlLabel, Checkbox} from "@material-ui/core";
import { requestHandler } from "../../utils/utils";
import { navigate } from "gatsby"
import Testers from "../Testers";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FileCopy } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import DeleteModal from "../DeleteModal";

const useStyles = makeStyles(() => ({
    tab: {
      color: '#FFF !important',
      maxWidth: "none",
      '&.Mui-selected': {
        backgroundColor: '#0053c7', 
        color: '#FFF !important',
        maxWidth: "none",
      }
    },
}));

const appDetail = () => {
    const [ tabValue, setTabValue ] = useState(1);
    const classes = useStyles();
    const tabClasses = {root: classes.tab};
    const queryParams = new URLSearchParams(window.location.search);
    const app_id = queryParams.get("app") ? queryParams.get("app") : "" ; 
    const [ apiName , setAPIName ] = useState("")
    const [ apiKeyPopup , showApiKeyPopup ] = useState(false);
    const [ apiKey, setAPIKey ] = useState("");
    const [ addTesterPopup, showAddTesterPopup ] = useState(false);
    const [ testerEmail, setTesterEmail ] = useState("");
    const [ apiKeyDisplay, setapiKeyDisplay] = useState(false);
    const [ apiKeyEmpty , setapiKeyEmpty ] = useState(false);
    const [ testerEmailEmpty, setTesterEmailEmpty ] = useState(false);
    const [ testerEmailErr, setTesterEmailErr] = useState("Tester email cannot be empty");
    const [ copied, setCopied ] = useState(false);
    const [ testerWarning , showTesterWarning ] = useState(false);
    const [ apiCheck , setApiCheck ] = useState(false);
    const [ deleteConfirm , showDeleteConfirm ] = useState({
        label: "",
        open : false , 
        title: "", 
        key: ""
    });
    const [ appData, setData ] = useState({
        "name": "", 
        "app_api_keys": [], 
        "description": ""
    });
    const [ testerList , setTesterList ] = useState({});

    const addAPIKeyBtnClick = () => {
        showApiKeyPopup(true);
    }

    const inviteTesterBtnClick = () => {
        showAddTesterPopup(true);
    }

    const deleteApp = async () => {
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `app/${app_id}` , null , headers, "DELETE");
        closeDeleteConfirm();
        if(result.status === "success") 
        {
            navigate("/user/apps")
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const deleteTesterBtnClick = async () => {
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}
        const result = await requestHandler(API_URL + `tester/${deleteConfirm.key}` , null , headers, "DELETE");
        closeDeleteConfirm();
        if(result.status === "success") 
        {
            toast.success(result.message); 
            callTesterListAPI();
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }     
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const deleteTesterConfirm = (tester_invite_id) => {
        showDeleteConfirm({
            label: "tester",
            title: "Remove access for Tester", 
            key : tester_invite_id, 
            open: true
        });
    }

    const deleteAPIKeyConfirm = (api_key_id) => {
        showDeleteConfirm({
            label: "apikey",
            title: "Revoke API KEY Access", 
            key : api_key_id, 
            open: true
        });
    }

    const deleteAppConfirm = () => {
        showDeleteConfirm({
            label: "app",
            title: "Delete the App", 
            key : "", 
            open: true
        });
    }

    const removeAPIKeyBtnClick = async () => {
        let api_key_id = deleteConfirm.key;
        let apiKeyId = encodeURIComponent(api_key_id);
        const data = {
            params : {
                api_key_id  : api_key_id
        }} 
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `api-key/${apiKeyId}` , data, headers, "DELETE");
        closeDeleteConfirm();
        if(result.status === "success") 
        {
            toast.success(result.message); 
            const appDataStub = appData.app_api_keys.filter(apiKey => apiKey.api_key_id !== api_key_id);
            setData({...appData, app_api_keys: appDataStub});
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const openTesterWarningModal = () => {
        showTesterWarning(true);
    }

    const handleClose = () => {
        showApiKeyPopup(false);
        setAPIKey("");
        setapiKeyDisplay(false);
        setAPIName("");
        setapiKeyEmpty(false);
        setCopied(false);
        setApiCheck(false);
    };

    const handleTesterClose = () => {
        showAddTesterPopup(false);
        setTesterEmail("");
        setTesterEmailEmpty(false)
    }

    const inviteTester = async () => { 
        if(testerEmail != "") {
            let regex = /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/;
            if(regex.test(testerEmail)) {
                setTesterEmailEmpty(false);
                const data = {
                    "app_id": app_id,
                    "email": testerEmail
                }
                const result:any = await requestHandler(API_URL + "tester/invite-tester" , data, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "POST")
                if(result.status === "success") 
                {
                    // toast.success(result.message); 
                    openTesterWarningModal();
                    callTesterListAPI();
                    handleTesterClose();
                }
                if(result.status === "failed") 
                {
                    toast.error(result.errorMessage); 
                }
                if(result === "login") {
                    toast.error("Not authenticated. Please login to continue"); 
                    navigate("/login");
                }
            }
            else {
                setTesterEmailEmpty(true);
                setTesterEmailErr("Please enter a valid email");
            }
        }
        else {
            setTesterEmailEmpty(true);
            setTesterEmailErr("Tester email cannot be empty");
        }
    }

    const updateApp = async ( appName = "", appDesc = "") => {
        const data = {
            "name": appName,
            "description": appDesc, 
            "app_id": app_id
        }
        const result:any = await requestHandler(API_URL + "app/" , data, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "PUT")
        if(result.status === "success") 
        {
            let temp = appData;
            temp.name = appName;
            temp.description = appDesc;
            setData({...temp});
            toast.success(result.message);
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage);
        }     
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const callTesterListAPI = async () => {
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `tester/list?app_id=${app_id}` , null, headers, "GET")
        if(result.status === "success") 
        {
            // toast.success(result.message); 
            setTesterList(result.data);
        }
        if(result.status === "failed") 
        {
            // toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const callAppsAPI = async () => {
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `app/${app_id}` , null, headers, "GET")
        if(result.status === "success") 
        {
            setData(result.data);
            callTesterListAPI();
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const handleApiCheck = (event) => {
        setApiCheck(event.target.checked);
    }
    
    useEffect(() => {
        callAppsAPI();
    }, [])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleAPICreate = async () => {
        if(apiName != "") {
            setapiKeyEmpty(false);
            const data = {
                "app_id": app_id,
                "name": apiName
            }
            const result:any = await requestHandler(API_URL + "api-key/" , data, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "POST")
            setapiKeyDisplay(true);
            if(result.status === "success") 
            {
                toast.success(result.message); 
                setAPIKey(result.data.api_key);
                const apiKeyObj = {
                    "name": result.data.name,
                    "api_key_id": result.data.api_key_id,
                    "api_key_type": result.data.api_key_type,
                    "scopes": result.data.scopes,
                    "active": result.data.active,
                    "created_at": result.data.created_at,
                    "updated_at": result.data.updated_at
                }
                const appDataStub = appData;
                appDataStub.app_api_keys.push(apiKeyObj);
                setData(appDataStub);
            }
            if(result.status === "failed") 
            {
                toast.error(result.errorMessage); 
            }
            if(result === "login") {
                toast.error("Not authenticated. Please login to continue"); 
                navigate("/login");
            }
                
        }
        else {
            setapiKeyEmpty(true);
        }
    }

    const closeDeleteConfirm = () => {
        showDeleteConfirm({
            label: "",
            open : false , 
            title: "", 
            key: ""
        })
    }

    return (
        <div className = "cls_appDetailWrapper">
            <div className = {styles.cls_appDetail}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Vertical tabs"
                    className={styles.cls_appDashVerticalTabs}
                >
                    <div className = {styles.appNameCont}>
                        <div className = {styles.appDetailName}>App</div>
                        <div className = {styles.cls_AppNameWrap}>
                            <img
                            src={"../../../connection-white.png"}
                            alt="SMITCH logo" height="40" width="40" />
                            <div className = {styles.cls_AppName}>{appData.name}</div>
                        </div>
                        
                    </div>
                    <Tab classes={tabClasses}  label = "Overview" id="vertical-tab-1" className = {styles.cls_appDetTabs}/>
                    <Tab classes={tabClasses}  label = "Testers" id="vertical-tab-2"  className = {styles.cls_appDetTabs}/>
                </Tabs>
                <TabPanel value={tabValue} index={1} className = {styles.cls_appDetTabPanel}>
                    <Overview data = {appData} handleApiKeyBtnClick = {addAPIKeyBtnClick} handleRevokeAccess = {deleteAPIKeyConfirm} handleAppDelete = {deleteAppConfirm} handleUpdateApp = {updateApp}/>
                </TabPanel>
                <TabPanel value={tabValue} index={2} className = {styles.cls_appDetTabPanel}>
                    <Testers data = {testerList} handleAddTester = {inviteTesterBtnClick} handleDelTester = {deleteTesterConfirm}/>
                </TabPanel>
            </div>
            {
                <Dialog open={apiKeyPopup} onClose={() => {}} aria-labelledby="form-dialog-title" className = "cls_apikeyPopup" fullWidth>
                    <DialogTitle id="form-dialog-title" className = {styles.cls_overviewTitle}>Create a new API Key</DialogTitle>
                    {
                        apiKeyDisplay === false && 
                        <>
                        <DialogContent>
                            <DialogContentText className = {styles.apiKeyText}>
                                Enter the Name of the API Key
                            </DialogContentText>
                            <TextField  
                                value = {apiName}
                                onChange = {(event) => setAPIName(event.target.value)}
                                autoFocus
                                id="name"
                                color="secondary"
                                className = {styles.cls_appDetinputBox}
                                label="Name"
                                type="text"
                                fullWidth
                            />
                            {
                                apiKeyEmpty === true && 
                                <DialogContentText className = {styles.apiKeyTextError}>
                                    API Name cannot be empty
                                </DialogContentText>
                            }
                        </DialogContent>
                        <DialogActions className = {styles.cls_apiBtnWrapper}>
                            <div className = {styles.cls_appCancelBtn} onClick={handleClose} color="primary">
                                Cancel
                            </div>
                            <div className = {styles.cls_appCreateBtn} onClick={() => handleAPICreate()} color="primary">
                                Create
                            </div>
                        </DialogActions>
                        </>
                    }
                    {
                        apiKeyDisplay === true && 
                        <>
                        <DialogContent>
                            <DialogContentText className = {styles.apiKeyText}>
                                New API Key created and <span className = {styles.apiKeyBoldText}> it will be displayed only once.</span>
                            </DialogContentText>
                            <CopyToClipboard text={apiKey} onCopy = {() => setCopied(true)}>
                                <div className = {styles.apiKeyValWrapper}>
                                    <div className = {styles.apiKeyVal}>
                                        {apiKey}
                                    </div>
                                    <div className = {styles.apiKeyCopyIcon}>
                                        <FileCopy />
                                    </div>
                                </div>
                            </CopyToClipboard>
                            { 
                                copied === true && 
                                <DialogContentText style = {{fontWeight : "bold"}} className = {styles.apiKeyText}>
                                    Copied!
                                </DialogContentText>
                            }
                            <DialogContentText className = {styles.apiKeyText}>
                                Please store it somewhere safe because as soon as you navigate away from this page, <b> we will not be able to retrieve or restore this generated token. </b>
                            </DialogContentText>

                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={apiCheck}
                                    onChange={handleApiCheck}
                                    name="checkedB"
                                    // color="primary"
                                />
                                }
                                label="I have copied the API Key"
                            />
                        </DialogContent>
                        <DialogActions className = {styles.cls_apiBtnWrapper}>
                            <div className = {styles.cls_appCancelBtn} onClick={handleClose} color="primary" data-disabled = {!apiCheck}>
                                Close
                            </div>
                        </DialogActions>
                        </>
                    }
                </Dialog>
            }
            {
                <Dialog open={addTesterPopup} onClose={handleTesterClose} aria-labelledby="form-dialog-title" className = "cls_apikeyPopup" fullWidth>
                    <DialogTitle id="form-dialog-title" className = {styles.cls_overviewTitle}>Invite Testers</DialogTitle>
                        <DialogContent>
                            <DialogContentText className = {styles.apiKeyText}>
                                Enter the email of the tester
                            </DialogContentText>
                            <DialogContentText className = {styles.apiKeyText}>
                                Please make sure that the tester is already a <b> registered user in MySmitch mobile app. </b>
                            </DialogContentText>
                            <TextField  
                                value = {testerEmail}
                                onChange = {(event) => setTesterEmail(event.target.value)}
                                autoFocus
                                color="secondary"
                                id="email"
                                className = {styles.cls_appDetinputBox}
                                label="email"
                                type="email"
                                fullWidth
                            />
                            {
                                testerEmailEmpty === true && 
                                <DialogContentText className = {styles.apiKeyTextError}>
                                    {testerEmailErr}
                                </DialogContentText>
                            }
                        </DialogContent>
                        <DialogActions className = {styles.cls_apiBtnWrapper}>
                            <div className = {styles.cls_appCancelBtn} onClick={handleTesterClose} color="primary">
                                Cancel
                            </div>
                            <div className = {styles.cls_appCreateBtn} onClick={() => inviteTester()} color="primary">
                                Invite
                            </div>
                        </DialogActions>
                </Dialog>
            }
            {
                <Dialog open={testerWarning} onClose={() => showTesterWarning(false)} aria-labelledby="form-dialog-title" className = "cls_apikeyPopup" fullWidth>
                    <DialogContent>
                        <CloseIcon style = {{ float: "right", margin: "0px 0px 10px 10px" , cursor: "pointer" }} onClick={() => showTesterWarning(false)}/>
                        <DialogContentText className = {styles.apiKeyText}>
                            Invite successfully sent to testers email address.
                            <br/>
                            Please note that the invite link sent to the testers email address is <b> valid only for 1 hour from now. </b>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            }
            {
                deleteConfirm.label === "tester" && 
                <DeleteModal title = {deleteConfirm.title}  open = {deleteConfirm.open} onDelete = {deleteTesterBtnClick}   handleClose = {() => closeDeleteConfirm()} />
            }
            {
                deleteConfirm.label === "apikey" && 
                <DeleteModal title = {deleteConfirm.title}  open = {deleteConfirm.open} onDelete = {removeAPIKeyBtnClick}   handleClose = {() => closeDeleteConfirm()} />
            }
            {
                deleteConfirm.label === "app" && 
                <DeleteModal title = {deleteConfirm.title}  open = {deleteConfirm.open} onDelete = {deleteApp}   handleClose = {() => closeDeleteConfirm()} />
            }
        </div>
    )
}

export default appDetail;