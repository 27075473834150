import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react"; 
import { Autocomplete, Box, Button, FormControl, FormHelperText, OutlinedInput, TextField , 
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { requestHandler } from "../utils/utils";
import { API_URL } from "../constants/constants";
import { toast } from "react-toastify";
import { countries } from "../constants/countrycode";
import { navigate } from "gatsby"
import TeamContext from "../context/TeamContextProvider";
import 'react-toastify/dist/ReactToastify.css';
import DeleteModal from "./DeleteModal";
const styles = require("../styles/userSettings.module.css"); 

const UserSettings = () => {
    const [ user, setData ] = useState({
        "uname": "", 
        "name": "", 
        "country": "", 
        "mobile": "", 
        "userID": "", 
        "email": "",
        "organisation": "",
        "industry": "",
    })
    const { userData , setUserData } = useContext(TeamContext);
    const  [ initCountry , setInitCountry] = useState(""); 
    const [ pwdPopup , showPwdPopup ] = useState(false);
    const [ oldPwd, setOldPwd ] = useState("");
    const [ pwdErr, setPwdErr] = useState("");
    const [ newPwd, setNewPwd] = useState("");
    const [ isSocialLogin, setSocialLogin] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [newPasswordShown, setnewPasswordShown] = useState(false);
    const [ deleteConfirm , showDeleteConfirm ] = useState({
        open : false , 
        title: ""
    });

    const togglePasswordVisiblity = ( val : string) => {
        if(val === "newPassword") 
        setnewPasswordShown(newPasswordShown ? false : true);
        else
        setPasswordShown(passwordShown ? false : true);
    };

    let icon:any ;
    if (passwordShown ==true) {
        icon = <Visibility className = {styles.passwordIcon} onClick={() => togglePasswordVisiblity("oldPassword")} />;
    } else if (passwordShown == false) {
        icon = <VisibilityOff className = {styles.passwordIcon} onClick={() => togglePasswordVisiblity("oldPassword")} />;
    }

    let newIcon:any ;
    if (newPasswordShown ==true) {
        newIcon = <Visibility className = {styles.passwordIcon} onClick={() => togglePasswordVisiblity("newPassword")} />;
    } else if (newPasswordShown == false) {
        newIcon = <VisibilityOff className = {styles.passwordIcon} onClick={() => togglePasswordVisiblity("newPassword")} />;
    }

    const handlePopupClose = () => {
        showPwdPopup(false);
        setPwdErr("");
        setOldPwd("");
        setNewPwd("");
        setPasswordShown(false);
        setnewPasswordShown(false)
    }

    const deleteDevConfirm = () => {
        showDeleteConfirm({
            title: "Remove Developer Account", 
            open: true
        });
    }

    const closeDeleteConfirm = () => {
        showDeleteConfirm({
            open : false , 
            title: ""
        })
    }

    const handleChangePwd = async () => {
        if(oldPwd != "" && newPwd != "")
        {
            let regex = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
            if( oldPwd === newPwd)
                setPwdErr("Old and New password cannot be the same");
            else if (!regex.test(newPwd))
                setPwdErr("Password must contain at least 8 characters, one uppercase, one number and one special case character");
            else {
                setPwdErr("");
                try {
                    const data = {
                        "old_password": oldPwd,
                        "new_password": newPwd
                    }
                    const result:any = await requestHandler(API_URL + "developer/change-password" , data, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "PUT")
                    if(result.status === "success") 
                    {
                        toast.success(result.message); 
                        handlePopupClose();
                        callLogoutAPI();
                    }
                    if(result.status === "failed") 
                    {
                        toast.error(result.errorMessage);
                    }      
                }
                catch (error) {
                    // Sentry.captureException(error);
                    toast.error(error.errorMessage); 
                    console.log("error", error.errorMessage);
                }
            }
            // handlePopupClose();
        }
        else {
            if(oldPwd == "" && newPwd === "")
                setPwdErr("Old and New Password must be filled")
            else {
                if( oldPwd === "") 
                    setPwdErr("Old Password cannot be empty");
                if( newPwd === "")
                    setPwdErr("New Password cannot be empty");
            }
        }
    }

    const getDevDetails = async () => {
        try {
            const result:any = await requestHandler(API_URL + "developer/" , null, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "GET")
            if(result.status === "success") 
            {
                // toast.success(result.message); 
                const user = {
                    "uname": result.data.user_name, 
                    "name": result.data.name,
                    "country" : result.data.country,
                    "mobile": result.data.mobile_number, 
                    "userID": result.data.developer_id, 
                    "email": result.data.email, 
                    "organisation": result.data.organisation,
                    "industry": result.data.industry,
                }
                setData(user);
                setInitCountry(result.data.country);
                if(result.data.github_account_id === null && result.data.google_account_id === null)
                    setSocialLogin("false");
                else 
                    setSocialLogin("true")
            }
            if(result.status === "failed") 
            {
                toast.error(result.errorMessage); 
            }      
        }
        catch (error) {
            // Sentry.captureException(error);
            toast.error(error.errorMessage); 
            console.log("error", error.errorMessage);
        }
    }

    useEffect(() => {
        getDevDetails();
    }, [])

    const validate = ( values : any) => {
        let errors = {};
        if (values.uname === "") {
            Object.assign(errors, {["uname"]: "UserName cannot be empty"});
        }
        if (values.country === "") {
            Object.assign(errors, {["country"]: "Country cannot be empty"});
        }
        if (values.email === "") {
            Object.assign(errors, {["email"]: "Email cannot be empty"});
        }
        return errors;
    };

    const deleteDev = async () => {
        try {
            const result:any = await requestHandler(API_URL + "developer/" , null, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "DELETE");
            closeDeleteConfirm();
            if(result.status === "success") 
            {
                toast.success(result.message); 
                localStorage.setItem("isLoggedIn", "false");
                localStorage.setItem("userData", "");
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                navigate("/")
            }
            if(result.status === "failed") 
            {
                toast.error(result.errorMessage); 
            }      
        }
        catch (error) {
            // Sentry.captureException(error);
            toast.error(error.errorMessage); 
            console.log("error", error.errorMessage);
        }
    }

    const callLogoutAPI = async () => {
        try {
            const result:any = await requestHandler(API_URL + "developer/logout" , null, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "POST")
            if(result.status === "success") 
            {
                toast.success(result.message); 
                localStorage.setItem("isLoggedIn", "false");
                localStorage.setItem("userData", "");
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                navigate("/")
            }
            if(result.status === "failed") 
            {
                toast.error(result.errorMessage); 
            }      
        }
        catch (error) {
            // Sentry.captureException(error);
            toast.error(error.errorMessage); 
            console.log("error", error.errorMessage);
        }
    }

    return (
        <>
        <div className = {styles.userSetPageWrapper}>
            <div className = {styles.userSetPage}>
                <div className = {styles.userSetTitle}>
                    Manage Your Account
                </div>
                <div className = {styles.userSetBody}>
                    <div className = {styles.userSetCont}>
                        <div className = {styles.devInfoTitle}>Developer Information</div>
                        <div className = {styles.userInfoWrap}>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    uname: user.uname ,
                                    name: user.name , 
                                    country: user.country, 
                                    email: user.email, 
                                    mobile: user.mobile,
                                    userID: user.userID, 
                                }}
                                validate={(initialValues) => validate(initialValues)} 
                                onSubmit={async (values: any) => {
                                    try {
                                        const data = {
                                            "user_name": values.uname,
                                            "country": values.country,
                                            "name": ( values.name === null || values.name === undefined ) ? "" : values.name ,
                                            "email": values.email, 
                                            "mobile_number": ( values.mobile === null || values.mobile === undefined ) ? "" : values.mobile, 
                                            "organisation": user.organisation,
                                            "industry": user.industry,
                                        } 
                                        const result:any = await requestHandler(API_URL + "developer/update" , data, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "PUT")
                                        if(result.status === "success") 
                                        {
                                            toast.success(result.message); 
                                            if(userData != null && userData != "") {
                                                let temp = JSON.parse(userData);
                                                temp.name = values.uname;
                                                temp.email = values.email; 
                                                setUserData(JSON.stringify(temp));
                                            }
                                        }
                                        if(result.status === "failed") 
                                        {
                                            toast.error(result.errorMessage); 
                                        }      
                                    }
                                    catch (error) {
                                        // Sentry.captureException(error);
                                        toast.error(error.errorMessage); 
                                        console.log("error", error.errorMessage);
                                    }
                                }}>

                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box my={3}>
                                            <FormControl className={styles.userSetFormControl} variant="outlined">
                                                <div id="Email" className = {styles.userSetFormLabel}>Email ID * </div>
                                                <OutlinedInput
                                                    id="outlined-email"
                                                    value={values.email}
                                                    onBlur={handleBlur('email')}
                                                    onChange={handleChange('email')}
                                                    className = {styles.useSetFormInput}
                                                    labelWidth={0}
                                                />
                                                { 
                                                    touched.email && errors.email && 
                                                    <FormHelperText style = {{color: "#f44336"}}>Email is Required</FormHelperText>
                                                }
                                            </FormControl>
                                            <FormControl className={styles.userSetFormControl} variant="outlined">
                                                <div id="name" className = {styles.userSetFormLabel}>Name</div>
                                                <OutlinedInput
                                                    id="outlined-name"
                                                    value={values.name}
                                                    onBlur={handleBlur('name')}
                                                    onChange={handleChange('name')}
                                                    className = {styles.useSetFormInput}
                                                    labelWidth={0}
                                                />
                                            </FormControl>
                                            <FormControl className={styles.userSetFormControl} variant="outlined">
                                                <div id="Country" className = {styles.userSetFormLabel}>Country *</div>
                                                <Autocomplete
                                                    id="country-select-demo"
                                                    style={{ width: 300 , color: "#FFF"}}
                                                    options={countries}
                                                    className = {styles.cls_CountrySelect}
                                                    value={values.country}
                                                    classes={{
                                                        option: styles.cls_CountryOpt,
                                                    }}
                                                    onChange={(event, newValue) => {
                                                        handleChange("country")(newValue)
                                                    }}
                                                    
                                                    renderInput={(params) => (
                                                        <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        id = "id_CountryInput"
                                                        className = "cls_CountryInput"
                                                        value={values.country}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                        }}
                                                        />
                                                    )}
                                                />
                                                { 
                                                    touched.country && errors.country && 
                                                    <FormHelperText style = {{color: "#f44336"}}>Country is required</FormHelperText>
                                                }
                                            </FormControl>
                                            <FormControl className={styles.userSetFormControl} variant="outlined">
                                                <div id="mobile" className = {styles.userSetFormLabel}>Mobile</div>
                                                <OutlinedInput
                                                    id="outlined-mobile"
                                                    value={values.mobile}
                                                    onBlur={handleBlur('mobile')}
                                                    onChange={handleChange('mobile')}
                                                    className = {styles.useSetFormInput}
                                                    labelWidth={0}
                                                />
                                            </FormControl>
                                            {
                                                isSocialLogin === "false" && 
                                                <FormControl className={styles.userSetFormControl} variant="outlined">
                                                    <div id="mobile" className = {styles.userSetFormLabel}>Change Password</div>
                                                    <OutlinedInput
                                                        id="outlined-mobile"
                                                        value=""
                                                        className = {styles.useSetFormInput}
                                                        labelWidth={0}
                                                        onClick = {() => showPwdPopup(true)}
                                                    />
                                                </FormControl>
                                            }
                                            <FormControl className={styles.userSetFormControl} variant="outlined">
                                                <div id="Username" className = {styles.userSetFormLabel}>Username * </div>
                                                <OutlinedInput
                                                    id="outlined-name"
                                                    value={values.uname}
                                                    onBlur={handleBlur('uname')}
                                                    onChange={handleChange('uname')}
                                                    className = {styles.useSetFormInput}
                                                    labelWidth={0}
                                                />
                                                { 
                                                    touched.name && errors.name && 
                                                    <FormHelperText style = {{color: "#f44336"}}>Username is required</FormHelperText>
                                                }
                                            </FormControl>
                                            <FormControl className={styles.userSetFormControl} variant="outlined">
                                                <div id="userID" className = {styles.userSetFormLabel}>Developer ID</div>
                                                <div className = {styles.useSetFormInputUserID}>{values.userID}</div>
                                                <div className = {styles.userIDHelper}>Note: Developer ID is unique and is not editable</div>
                                            </FormControl>
                                        </Box>
                                        <Box my={2}>
                                            <Button
                                                // className = {styles.loginBtn}
                                                color="primary"
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                className = {styles.useSetSubBtn}
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    
                </div>
                <div className = {styles.userSetBody}>
                    <div className = {styles.userAccDeleteText}>
                        Your identification is a requirement to use this website. You can erase your data, withdraw your consent and restrict processing of your personal information by clicking the "Close Account" button. Doing so will permanently delete all of your personal data stored in your account. As a result, your account will be closed and no longer be available.
                    </div>
                    <div className = {styles.userDeleteBtn} onClick = {() => deleteDevConfirm()}>
                        Close Account
                    </div>
                </div>
            </div>
            <div className = {styles.userLogoutWrap}>
                <div className = {styles.userLogoutBtn} onClick = {() => callLogoutAPI()}>LOGOUT</div>
            </div>
        </div>
        {
            <Dialog open={pwdPopup} onClose={handlePopupClose} aria-labelledby="form-dialog-title" className = "cls_editAppPopup" fullWidth>
                <DialogTitle id="form-dialog-title" className = {styles.cls_overviewTitle}>Change Password</DialogTitle>
                    <DialogContent>
                        <TextField  
                            value = {oldPwd}
                            onChange = {(event) => setOldPwd(event.target.value)}
                            autoFocus
                            color="secondary"
                            id="oldPwd"
                            className = {styles.cls_appDetinputBox}
                            label="Old Password"
                            type= { passwordShown ? "text" : "password" }
                            fullWidth
                            InputProps={{
                                endAdornment: icon
                            }}
                        />
                        <TextField  
                            value = {newPwd}
                            onChange = {(event) => setNewPwd(event.target.value)}
                            autoFocus
                            color="secondary"
                            id="newPwd"
                            className = {styles.cls_appDetinputBox}
                            label="New Password"
                            type= { newPasswordShown ? "text" : "password" }
                            fullWidth
                            InputProps={{
                                endAdornment: newIcon
                            }}
                        />
                        {
                            pwdErr !== "" && 
                            <DialogContentText className = {styles.apiKeyTextError}>
                                {pwdErr}
                            </DialogContentText>
                        }
                    </DialogContent>
                    <DialogActions className = {styles.cls_apiBtnWrapper}>
                        <div className = {styles.cls_appCancelBtn} onClick={handlePopupClose} color="primary">
                            Cancel
                        </div>
                        <div className = {styles.cls_appCreateBtn} color="primary" onClick = {() => handleChangePwd()}>
                            Save
                        </div>
                    </DialogActions>
            </Dialog>
        }
        {
            <DeleteModal title = {deleteConfirm.title}  open = {deleteConfirm.open} onDelete = {deleteDev}   handleClose = {() => closeDeleteConfirm()} />
        }
        </>
    )
}

export default UserSettings;