import { Box, Typography } from '@material-ui/core';
import React from 'react';

const TabPanel = props => {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style = {{width : "100%", backgroundColor: "#e0e0e0"}}
        >
        {value === index && (
            <Box p={3}>
                {children}
            </Box>
        )}
        </div>
    )   
}

export default TabPanel;