// extracted by mini-css-extract-plugin
export const cls_appDashEmptyWrapper = "appDashboard-module--cls_appDashEmptyWrapper--3x1-S";
export const cls_appDashWrapper = "appDashboard-module--cls_appDashWrapper--2lzEy";
export const cls_appListWrapper = "appDashboard-module--cls_appListWrapper--1LfCf";
export const cls_appDashEmptyHeader = "appDashboard-module--cls_appDashEmptyHeader--3RQ7s";
export const cls_appDashEmptyTitle = "appDashboard-module--cls_appDashEmptyTitle--1d0Vj";
export const cls_appEmptyDesc = "appDashboard-module--cls_appEmptyDesc--2oMjV";
export const cls_appCreateAppBtn = "appDashboard-module--cls_appCreateAppBtn--1U5S7";
export const cls_appDashEmptyBody = "appDashboard-module--cls_appDashEmptyBody--Ob2JU";
export const cls_appDashTabs = "appDashboard-module--cls_appDashTabs--u6wXm";
export const cls_appDahTabPanel = "appDashboard-module--cls_appDahTabPanel--DG2aS";
export const cls_AppListWrapper = "appDashboard-module--cls_AppListWrapper--1nkDC";
export const cls_AppListHeadCont = "appDashboard-module--cls_AppListHeadCont--2gP2c";
export const cls_AppListTitle = "appDashboard-module--cls_AppListTitle--2DxOS";
export const cls_AppListCreateBtn = "appDashboard-module--cls_AppListCreateBtn--20lr2";
export const cls_AppListBody = "appDashboard-module--cls_AppListBody--26WpV";
export const cls_AppListCont = "appDashboard-module--cls_AppListCont--1JLeQ";
export const cls_AppListItem = "appDashboard-module--cls_AppListItem--1KsNv";
export const cls_AppList = "appDashboard-module--cls_AppList--b-3Nt";
export const cls_AppNameWrap = "appDashboard-module--cls_AppNameWrap--2QpIX";
export const cls_AppName = "appDashboard-module--cls_AppName--1aFTt";
export const cls_AppUpdatedDetails = "appDashboard-module--cls_AppUpdatedDetails--3Biiv";
export const cls_AppUpdatedTitle = "appDashboard-module--cls_AppUpdatedTitle--2M-j3";
export const cls_AppUpdatedTime = "appDashboard-module--cls_AppUpdatedTime--xoYo0";
export const cls_AppStatus = "appDashboard-module--cls_AppStatus--vJza3";
export const cls_inDevBtn = "appDashboard-module--cls_inDevBtn--1twql";
export const cls_createAppDashboard = "appDashboard-module--cls_createAppDashboard--2-Fvo";
export const cls_createAppTitle = "appDashboard-module--cls_createAppTitle--fpmX7";
export const cls_createAppBody = "appDashboard-module--cls_createAppBody--2mtJ-";
export const cls_createAppSubBody = "appDashboard-module--cls_createAppSubBody--3z_GY";
export const cls_createAppSubTitle = "appDashboard-module--cls_createAppSubTitle--3CXoO";
export const cls_createAppInpWrap = "appDashboard-module--cls_createAppInpWrap--1Nvh3";
export const cls_createAppInp = "appDashboard-module--cls_createAppInp--3M_qi";
export const cls_subsBtn = "appDashboard-module--cls_subsBtn--2MTAK";
export const cls_errorText = "appDashboard-module--cls_errorText--1suY6";
export const cls_appCreateBtn = "appDashboard-module--cls_appCreateBtn--3g1TO";
export const cls_appCancelBtn = "appDashboard-module--cls_appCancelBtn--ul89P";
export const userSetFormControl = "appDashboard-module--userSetFormControl--3ruXd";
export const userSetFormLabel = "appDashboard-module--userSetFormLabel--2SBcC";
export const useSetFormInput = "appDashboard-module--useSetFormInput--2gmig";
export const cls_CountrySelect = "appDashboard-module--cls_CountrySelect--315FD";
export const setFormInput = "appDashboard-module--setFormInput--2K0Pu";
export const useSetSubBtn = "appDashboard-module--useSetSubBtn--2mD9E";
export const cls_CountryInput = "appDashboard-module--cls_CountryInput--2WMWa";
export const userSettingsModuleUseSetFormInput2GQAX = "appDashboard-module--userSettings-module--useSetFormInput--2GQAX--wrjzB";
export const userSetBody = "appDashboard-module--userSetBody--2ekKy";
export const cls_appDetail = "appDashboard-module--cls_appDetail--2-97p";
export const cls_apiBtnWrapper = "appDashboard-module--cls_apiBtnWrapper--1bU8-";
export const apiKeyBoldText = "appDashboard-module--apiKeyBoldText--NmHG3";
export const apiKeyValWrapper = "appDashboard-module--apiKeyValWrapper--3bgLr";
export const apiKeyVal = "appDashboard-module--apiKeyVal--2YF81";
export const userSetImg = "appDashboard-module--userSetImg--1HN5v";