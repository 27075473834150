import { Box, Button, FormControl, FormHelperText, InputBase } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react"; 
import { API_URL } from "../../constants/constants";
import { requestHandler } from "../../utils/utils";
const styles = require("../../styles/appDashboard.module.css");
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from "formik";
import { OutlinedInput } from "@material-ui/core";

const CreateApp = ({teamID , handleSubmit}) => {
    const [ appName , setAppName ] = useState("");
    const team_id = teamID ? teamID : "" ; 
    const [picture, setPicture] = useState(null); 
    const [thumb, setThumb] = useState("https://d199xmsg3owom4.cloudfront.net/images/smitch_logo_white.png"); 

    const handleChange = (event) => {
        setAppName(event.target.value);
    }

    const onChangePicture = e => {
        console.log('picture: ', picture);
        setPicture(e.target.files[0]);
        let reader = new FileReader();

        reader.onloadend = () => {
            //@ts-ignore
            setThumb(reader.result);
        };

        reader.readAsDataURL(e.target.files[0]);
    };

    const createAnApp = async (values : any) => {
        try {
            const data = {
                "team_id": team_id, 
                "name": values.name, 
                "description": values.desc, 
                "app_photo_url": picture === null ? "https://d199xmsg3owom4.cloudfront.net/images/smitch_logo_white.png" : "", 
                "redirect_uri": values.redirect_uri
            }
            const result:any = await requestHandler(API_URL + "app/" , data, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "POST")
            if(result.status === "success") 
            {
                toast.success(result.message);
                handleSubmit(result , picture);
            }
            if(result.status === "failed") 
            {
            }      
        }
        catch (error) {
            // Sentry.captureException(error);
            toast.error(error.errorMessage); 
            console.log("error", error.errorMessage);
        }
    }

    const validate = ( values : any) => {
        let errors = {};
        if (values.name === "") {
            Object.assign(errors, {["name"]: "Team name cannot be empty"});
        }
        if (values.redirect_uri === "") {
            Object.assign(errors, {["redirect_uri"]: "Redirect Uri cannot be empty"});
        }
        return errors;
    };

    return (
        <>
            <div className = {styles.cls_createAppDashboard}>
                <div className = {styles.cls_createAppHead}>
                    <div className = {styles.cls_createAppTitle}>Create a new app</div>
                </div>
                <div className = {styles.userSetBody}>
                    <div className = {styles.userSetCont}>
                        <div className = {styles.userInfoWrap}>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    name: "" ,
                                    desc: "", 
                                    redirect_uri: "", 
                                    file: null
                                }}
                                validate={(initialValues) => validate(initialValues)} 
                                onSubmit={ (values: any) => {
                                    createAnApp(values);
                                    console.log(
                                        JSON.stringify(
                                          { 
                                            fileName: values.file.name, 
                                            type: values.file.type,
                                            size: `${values.file.size} bytes`
                                          },
                                          null,
                                          2
                                        )
                                      );
                                }}>

                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box my={3}>
                                            <FormControl className={styles.userSetFormControl} variant="outlined">
                                                <div id="app_name" className = {styles.userSetFormLabel}>App Name</div>
                                                <OutlinedInput
                                                    id="outlined-app_name"
                                                    value={values.name}
                                                    onBlur={handleBlur('name')}
                                                    onChange={handleChange('name')}
                                                    className = {styles.setFormInput}
                                                    labelWidth={0}
                                                />
                                                { 
                                                    touched.name && errors.name && 
                                                    <FormHelperText style = {{color: "#f44336"}}>App Name is Required</FormHelperText>
                                                }
                                            </FormControl>
                                            <FormControl className={styles.userSetFormControl} variant="outlined">
                                                <div id="desc" className = {styles.userSetFormLabel}>Description</div>
                                                <OutlinedInput
                                                    id="outlined-app_name"
                                                    value={values.desc}
                                                    onBlur={handleBlur('desc')}
                                                    onChange={handleChange('desc')}
                                                    className = {styles.setFormInput}
                                                    labelWidth={0}
                                                />
                                            </FormControl>
                                            <FormControl className={styles.userSetFormControl} variant="outlined">
                                                <div id="redirect_uri" className = {styles.userSetFormLabel}>Redirect URI</div>
                                                <OutlinedInput
                                                    id="outlined-app_name"
                                                    value={values.redirect_uri}
                                                    onBlur={handleBlur('redirect_uri')}
                                                    onChange={handleChange('redirect_uri')}
                                                    className = {styles.setFormInput}
                                                    labelWidth={0}
                                                />
                                            </FormControl>
                                            <FormControl className={styles.userSetFormControl} variant="outlined">
                                                <div id="logo" className = {styles.userSetFormLabel}>Choose Logo</div>
                                                <img src={thumb}
                                                    className = {styles.userSetImg}
                                                    alt={"file"}
                                                    height={60}
                                                    width={60} 
                                                />
                                                <label className="custom-file-upload">
                                                    {/* <input type="file"/> */}
                                                    <input id="file" name="file" type="file" onChange={(event) => {
                                                        console.log(event.currentTarget.files[0], "event.currentTarget.files[0]")
                                                        onChangePicture(event)
                                                    }} className="form-control" />
                                                    <i className="fa fa-cloud-upload"></i> Upload
                                                </label>
                                                
                                                
                                            </FormControl>
                                            {/* <FormControl 
                                                variant="standard" 
                                                fullWidth 
                                                className = {styles.cls_IndustrySelect}
                                                >
                                                <div id="appearance" className = {styles.userSetFormLabel}>Appearance </div>
                                                <Select
                                                labelId="appearance"
                                                id="appearance"
                                                value="dark"
                                                onBlur={handleBlur}>
                                                    <MenuItem value="dark">Dark</MenuItem>
                                                </Select>
                                            </FormControl> */}

                                        </Box>
                                        <Box my={2}>
                                            <Button
                                                // className = {styles.loginBtn}
                                                color="primary"
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                className = {styles.useSetSubBtn}
                                            >
                                                CREATE APP
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                {/* <div className = {styles.cls_createAppBody}>
                    <div className = {styles.cls_createAppSubTitle}>App Name</div>
                    <div className = {styles.cls_createAppSubBody}>
                        <div className = {styles.cls_createAppInpWrap}>
                            <InputBase
                                value = {appName}
                                className={styles.cls_createAppInp}
                                onChange = {(e) => handleChange(e)}
                                placeholder=""
                                inputProps={{ 'aria-label': 'App Name' }}
                            />
                            
                        </div>
                        <div className = {styles.cls_subsBtn} onClick = {() => createAnApp()}>
                            Create App
                        </div>
                    </div>
                    { 
                        error && 
                        <FormHelperText className = {styles.cls_errorText}>Please enter App name</FormHelperText> 
                    }
                </div> */}
            </div>
        </>
    )
}

export default CreateApp;