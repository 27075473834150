// extracted by mini-css-extract-plugin
export const userSetPage = "userSettings-module--userSetPage--2_Wc1";
export const userSetPageWrapper = "userSettings-module--userSetPageWrapper--2oax7";
export const userSetTitle = "userSettings-module--userSetTitle--2Mlhx";
export const passwordIcon = "userSettings-module--passwordIcon--1uZFJ";
export const userSetBody = "userSettings-module--userSetBody--ebXfH";
export const devInfoTitle = "userSettings-module--devInfoTitle--2c-Xt";
export const userSetFormControl = "userSettings-module--userSetFormControl--1DMVV";
export const userSetFormLabel = "userSettings-module--userSetFormLabel--tnTI-";
export const useSetFormInput = "userSettings-module--useSetFormInput--2GQAX";
export const cls_CountrySelect = "userSettings-module--cls_CountrySelect--xmOeG";
export const setFormInput = "userSettings-module--setFormInput--33BiB";
export const useSetSubBtn = "userSettings-module--useSetSubBtn--3XHS-";
export const cls_CountryInput = "userSettings-module--cls_CountryInput--3lkGb";
export const userSettingsModuleUseSetFormInput2GQAX = "userSettings-module--userSettings-module--useSetFormInput--2GQAX--1pYv3";
export const countrySelectDemo = "userSettings-module--country-select-demo--3QIWV";
export const useSetFormInputUserID = "userSettings-module--useSetFormInputUserID--27mtg";
export const userIDHelper = "userSettings-module--userIDHelper--24JAz";
export const userAccDeleteText = "userSettings-module--userAccDeleteText--1h8w6";
export const userDeleteBtn = "userSettings-module--userDeleteBtn--116PL";
export const userLogoutWrap = "userSettings-module--userLogoutWrap--_yQOl";
export const userLogoutBtn = "userSettings-module--userLogoutBtn--2pUea";
export const cls_IndustrySelect = "userSettings-module--cls_IndustrySelect--12b3y";
export const userReSetPage = "userSettings-module--userReSetPage--3rp56";
export const apiKeyTextError = "userSettings-module--apiKeyTextError--2DmWS";
export const cls_appDetinputBox = "userSettings-module--cls_appDetinputBox--8wp0q";
export const cls_overviewTitle = "userSettings-module--cls_overviewTitle--6g4jK";
export const cls_appCreateBtn = "userSettings-module--cls_appCreateBtn--4mZxY";
export const cls_appCancelBtn = "userSettings-module--cls_appCancelBtn--2To8o";
export const cls_apiBtnWrapper = "userSettings-module--cls_apiBtnWrapper--ACHZI";