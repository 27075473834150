import { makeStyles, Tab, Tabs, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Box, FormControl, InputLabel, Select, MenuItem, FormHelperText, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import React, {useState , useEffect, useContext } from "react";
import { API_URL } from "../../constants/constants";
const styles = require("../../styles/appDashboard.module.css");
import { toast } from "react-toastify";
import {navigate} from "gatsby";
import { requestHandler } from "../../utils/utils";
import TeamContext from "../../context/TeamContextProvider";
import DeleteModal from "../DeleteModal";
import Overview from "../Overview";
import CreateApp from "./createApp";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FileCopy } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    tab: {
      color: '#FFF',
      '&.Mui-selected': {
        color: '#0053C7'
      }
    },
}));

const AppDashboard = () => {
    const classes = useStyles();
    const [ listData , setData ] = React.useState([]);
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get("tab") ? queryParams.get("tab") : "0";
    const [ teamData , setTeamData ] = React.useState();
    const { setUserData , userData} = useContext(TeamContext);
    const [ createAppPage , showCreateApp ] = useState(false);
    const [ teamID, setTeamID] = useState("");
    const [ apiKeyPopup , showAPIKeyPopup ] = useState(false);
    const [ photoUrl , setPhotoUrl ] = useState("");
    const [ appsData , setAppData ] = useState({
        "name": "", 
        "description": "", 
        "redirect_uri": "", 
        "app_photo_url": ""
    });
    const [ teamName , setTeamName ] = useState("");
    const [ role , setRole ] = useState("");
    const [ app_id, setAppId] = useState("");
    const [ deleteConfirm , showDeleteConfirm ] = useState({
        label: "",
        open : false , 
        title: "", 
        key: ""
    });
    const [ apiKey, setAPIKey ] = useState("");
    const [ copied, setCopied ] = useState(false);
    const [ apiCheck , setApiCheck ] = useState(false);

    useEffect(() => {
        if(userData != null && userData != "") {
            const temp = JSON.parse(userData);
            setTeamID(temp.team_id);
            setTeamName(temp.teamName);
        }
    }, [])

    useEffect(() => {
        if(userData != null && userData != "") {
            const temp = JSON.parse(userData);
            setTeamID(temp.team_id);
            setTeamName(temp.teamName);
        }
    }, [userData])

    useEffect(()=> {
        if(teamID != "") {
            // callAppsAPI();
            callTeamsAPI();
        }
    },[teamID])

    const callAppsAPI = async (app_id : string) => {
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `app/${app_id}` , null, headers, "GET")
        if(result.status === "success") 
        {
            setAppData(result.data);
            setPhotoUrl(result.data.app_photo_url);
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const callTeamsAPI = async () => {
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `team/${teamID}` , null , headers, "GET")
        if(result.status === "success") 
        {
            setTeamData(result.data.developers);
            setData(result.data.apps);
            if(result.data.apps && result.data.apps[0] && result.data.apps[0].app_id) {
                callAppsAPI(result.data.apps[0].app_id)
                setAppId(result.data.apps[0].app_id)
            }
            let userEmail = JSON.parse(userData).email
            let currUser = result.data.developers.find((e) => e.email === userEmail)
            setRole(currUser.role);
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const closeDeleteConfirm = () => {
        showDeleteConfirm({
            label: "",
            open : false , 
            title: "", 
            key: ""
        })
    }

    const deleteApp = async () => {
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `app/${app_id}` , null , headers, "DELETE");
        closeDeleteConfirm();
        if(result.status === "success") 
        {
            window.location.reload();
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const deleteAppConfirm = () => {
        showDeleteConfirm({
            label: "app",
            title: "Delete the App", 
            key : "", 
            open: true
        });
    }

    const updateApp = async ( name = "", desc = "" , redirect_uri = "", app_photo_url = "Test") => {
        const data = {
            "app_id": app_id,
            "name": name,
            "description": desc,
            "app_photo_url": app_photo_url ,
            "redirect_uri": redirect_uri
        }
        const result:any = await requestHandler(API_URL + "app/" , data, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "PUT")
        if(result.status === "success") 
        {
            setAppData(result.data);
            toast.success(result.message);
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage);
        }     
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const updateAppOpt = async ( name = "", desc = "" , redirect_uri = "", app_photo_url = "Test", appId) => {
        const data = {
            "app_id": appId,
            "name": name,
            "description": desc,
            "app_photo_url": app_photo_url ,
            "redirect_uri": redirect_uri
        }
        const result:any = await requestHandler(API_URL + "app/" , data, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "PUT")
        if(result.status === "success") 
        {
            setAppData(result.data);
            setPhotoUrl(result.data.app_photo_url)
            toast.success(result.message);
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage);
        }     
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }
    
    const uploadImage = async ( picture : any , app_id : string) => {
        var formData = new FormData();
        if(picture != null) {
            // @ts-ignore
            formData.append("image", picture);
        }
        const result:any = await requestHandler(API_URL + `app/${app_id}/image` , formData, { "Content-Type": "multipart/form-data"  , "access_token": localStorage.getItem("access_token")}, "PUT")
        if(result.status === "success") 
        {
            console.log("result", result);
            setPhotoUrl(result.data.app_photo_url);
            let temp = appsData;
            temp.app_photo_url = result.data.app_photo_url;
            setAppData(result.data);
            toast.success(result.message);
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage);
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const createAppSuccess = (result : any , picture : any) => {
        if(picture === null) {
            let temp = "https://d199xmsg3owom4.cloudfront.net/images/smitch_logo_white.png";  
            if(result.data && result.data.name && result.data.description && result.data.redirect_uri &&  result.data.app_id)
                updateAppOpt(result.data.name , result.data.description , result.data.redirect_uri, temp, result.data.app_id)
        }     
        else
            uploadImage(picture , result.data.app_id);
        showCreateApp(false);
        setData([result.data]);
        setAppId(result.data.app_id);
        setPhotoUrl(result.data.app_photo_url)
        showAPIKeyPopup(true);
        setAPIKey(result.data.client_secret)
    }

    const handleClose = () => {
        showAPIKeyPopup(false);
        setAPIKey("");
        // setapiKeyDisplay(false);
        // setAPIName("");
        // setapiKeyEmpty(false);
        setCopied(false);
        setApiCheck(false);
    };

    const handleApiCheck = (event) => {
        setApiCheck(event.target.checked);
    }

    return (
        <>
        <div className = {styles.cls_appDashWrapper}>
        {
            listData.length === 0 && !createAppPage &&
            <div className = {styles.cls_appDashEmptyWrapper}>
                <div className = {styles.cls_appDashEmptyHeader}>
                    <img
                        src={"../../../shuttle_white.png"}
                        alt="SMITCH logo" height="116" width="116" />
                    <div className = {styles.cls_appDashEmptyTitle}>
                        Create your first app
                    </div>
                </div>
                <div className = {styles.cls_appDashEmptyBody}>
                    <div className = {styles.cls_appEmptyDesc}>
                        Here in Smitch developer workspace, you will be able to create and manage your apps you made to connect with the Smitch ecosystem. Start by creating your first app today.
                    </div>
                    <div className = {styles.cls_appCreateAppBtn} onClick = {() => showCreateApp(true)}>Create An App</div>
                </div>
            </div>
        }
        {/* {
            <AppList data = {listData} team = {teamID} role = {role} />
        } */}
        {
            listData.length > 0 && !createAppPage &&
            <div className = {styles.cls_appDetail}>
                <Overview  photoUrl = {photoUrl} uploadImage = {uploadImage} data = {appsData} handleAppDelete = {deleteAppConfirm} handleUpdateApp = {updateApp}/>
            </div>
        }
        {
            deleteConfirm.label === "app" && 
            <DeleteModal title = {deleteConfirm.title}  open = {deleteConfirm.open} onDelete = {deleteApp}   handleClose = {() => closeDeleteConfirm()} />
        }
        </div>
        {
            createAppPage && 
            <CreateApp teamID = {teamID} handleSubmit = {createAppSuccess} />
        }
        {
            <Dialog open={apiKeyPopup} onClose={() => {}} aria-labelledby="form-dialog-title" className = "cls_apikeyPopup" fullWidth>
                <>
                <DialogContent>
                    <DialogContentText className = {styles.apiKeyText}>
                        New Client Secret created and <span className = {styles.apiKeyBoldText}> it will be displayed only once.</span>
                    </DialogContentText>
                    <CopyToClipboard text={apiKey} onCopy = {() => setCopied(true)}>
                        <div className = {styles.apiKeyValWrapper}>
                            <div className = {styles.apiKeyVal}>
                                {apiKey}
                            </div>
                            <div className = {styles.apiKeyCopyIcon}>
                                <FileCopy />
                            </div>
                        </div>
                    </CopyToClipboard>
                    { 
                        copied === true && 
                        <DialogContentText style = {{fontWeight : "bold"}} className = {styles.apiKeyText}>
                            Copied!
                        </DialogContentText>
                    }
                    <DialogContentText className = {styles.apiKeyText}>
                        Please store it somewhere safe because as soon as you navigate away from this page, <b> we will not be able to retrieve or restore this generated token. </b>
                    </DialogContentText>

                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={apiCheck}
                            onChange={handleApiCheck}
                            name="checkedB"
                            // color="primary"
                        />
                        }
                        label="I have copied the Client Secret"
                    />
                </DialogContent>
                <DialogActions className = {styles.cls_apiBtnWrapper}>
                    <div className = {styles.cls_appCancelBtn} onClick={handleClose} color="primary" data-disabled = {!apiCheck}>
                        Close
                    </div>
                </DialogActions>
                </>
            </Dialog>
        }
        </>
    )
}

export default AppDashboard;