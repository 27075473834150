import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Box, FormControl, OutlinedInput } from "@material-ui/core";
import React, { useEffect, useState } from "react";
const styles = require("../styles/appDetail.module.css");
import {CopyToClipboard} from 'react-copy-to-clipboard';

const Overview = ({data, handleAppDelete , handleUpdateApp, uploadImage, photoUrl}) => {
    const [ editApp , showEditPopup ] = useState(false);
    const [ appName , changeAppName ] = useState("");
    const [ appDesc, changeAppDesc ] = useState("");
    const [ redirectUri, changeRedirectUri ] = useState("");
    const [ appNameErr, setAppNameErr ] = useState("");
    const [ appDescErr, setAppDescErr ] = useState("");
    const [ appUriErr , setappUriErr ] = useState("");
    const [ app_photo_url , setAppPhotoUrl ] = useState(photoUrl)
    const [ copied , setCopied ] = useState(false);
    const [ editLogo , showEditLogo ] = useState(false);
    const [ fileImageErr , showfileImageErr] = useState("");
    const [ picture, setPicture] = useState({}); 
    const [ thumb, setThumb] = useState(""); 

    const handlePopupClose = () => {
        showEditPopup(false);
        showEditLogo(false);
        showfileImageErr("");
        setAppNameErr("");
        setAppDescErr("");
        setappUriErr("")
        if(data.name) 
            changeAppName(data.name)
        if(data.description) 
            changeAppDesc(data.description)
        if(data.redirect_uri)
            changeRedirectUri(data.redirect_uri)
        if(photoUrl) {
            setAppPhotoUrl(photoUrl);
        }
        
    }

    const onChangePicture = e => {
        console.log('picture: ', picture);
        setPicture(e.target.files[0]);
        let reader = new FileReader();

        reader.onloadend = () => {
            //@ts-ignore
            setThumb(reader.result);
        };

        reader.readAsDataURL(e.target.files[0]);
    };

    useEffect(() => {
        if(data.name) 
            changeAppName(data.name)
        if(data.description) 
            changeAppDesc(data.description)
        if(data.redirect_uri)
            changeRedirectUri(data.redirect_uri)
            // uploadImage( getBase64Image , data.app_id)
    },[data]);

    useEffect(() => {
        if(photoUrl) {
            setAppPhotoUrl(photoUrl);
            setThumb(photoUrl)
        }
    },[photoUrl])

    const handleSave = () => {
        if(appName === "" || appDesc === "" || redirectUri === "") {
            if(appName === "")
                setAppNameErr("App Name cannot be empty");
            if(appDesc === "")
                setAppDescErr("App Description cannot be empty");
            if(redirectUri === "") 
                setappUriErr("Redirect Uri cannot be empty");
        }
        else {
            handleUpdateApp(appName, appDesc , redirectUri , app_photo_url);
            handlePopupClose();
        }
    }

    const handleSaveLogo = () => {
        if(picture == null) {
            showfileImageErr("App Logo Cannot be Empty");
        }
        else {
            uploadImage(picture , data.app_id);
            handlePopupClose();
        }
    }

    return (
        <>
        <div className = {styles.cls_overViewWrapper}>
            <div className = {styles.cls_overViewPage}>
                <div className = {styles.cls_overviewPart1}>
                    <div className = {styles.cls_overviewTitleWrap} style = {{marginBottom : "20px"}}>
                        <div className = {styles.cls_overviewTitle}>
                            <img key={Date.now()} src = {`${app_photo_url}?${new Date().getTime()}`}  alt = "logo" style = {{ width : "40px", height : "40px"}} />
                        </div>
                        <div className = {styles.cls_overviewAppStatusWrap}>
                            <div className = {styles.cls_overviewEdit} onClick = {() => showEditLogo(true)}>
                                Change
                            </div>
                        </div>
                        {/* <div className = {styles.cls_overviewAppStatusWrap}>
                            <label className="custom-file-upload">
                                <input id="file" name="file" type="file" onChange={(event) => {
                                    console.log(event.currentTarget.files[0], "event.currentTarget.files[0]")
                                }} className="form-control" />
                                <i className="fa fa-cloud-upload"></i> Change
                            </label>
                        </div> */}
                    </div>
                    <div className = {styles.cls_overviewTitleWrap}>
                        <div className = {styles.cls_overviewTitle}><span className = {styles.cls_overviewTitleSpan}>{data.name}</span></div>
                        <div className = {styles.cls_overviewAppStatusWrap}>
                            <div className = {styles.cls_overviewEdit} onClick = {() => showEditPopup(true)}>
                                Edit App
                            </div>
                        </div>
                    </div>
                    <div className = {styles.cls_overviewDescWrap}>
                        <div className = {styles.cls_overviewDesc}>{data.description} </div>
                        {/* <div className = {styles.cls_overviewAppEditBtn}>
                            <div className = {styles.cls_overviewEdit} onClick = {() => showEditPopup(true)}>Edit</div>
                        </div> */}
                    </div>
                </div>
                <div className = {styles.cls_overviewPart1}>
                    <div className = {styles.cls_overviewTitle}>Configuration</div>
                    <Box my={3}>
                        <FormControl className={styles.userSetFormControl} variant="outlined">
                            <div id="Callback" className = {styles.userSetFormLabel}>Redirect URI</div>
                            <OutlinedInput
                                id="outlined-Callback"
                                value={data.redirect_uri ? data.redirect_uri : ""}
                                className = {styles.setFormInput}
                                labelWidth={0}
                                disabled={true}
                            />
                        </FormControl>
                        <FormControl className={styles.userSetFormControl} variant="outlined">
                            <div id="Client_ID" className = {styles.userSetFormLabel}>Client ID</div>
                            <OutlinedInput
                                id="outlined-Client_ID"
                                value={data.app_id}
                                className = {styles.setFormInput}
                                labelWidth={0}
                                disabled={true}
                            />
                            <CopyToClipboard text={data.app_id} onCopy = {() => setCopied(true)}>
                                <div className = {styles.cls_overviewAppEditBtnCopy}>
                                    <div className = {styles.cls_overviewEdit} >Copy</div>
                                    {   copied ? <div className={styles.cls_copySpan}>Copied.</div> : null}
                                </div>
                            </CopyToClipboard>
                            
                           
                        </FormControl>
                        <FormControl className={styles.userSetFormControl} variant="outlined">
                            <div id="Client_Secret" className = {styles.userSetFormLabel}>Client Secret</div>
                            <OutlinedInput
                                id="outlined-Client_Secret"
                                value={"****"}
                                className = {styles.setFormInput}
                                disabled={true}
                                labelWidth={0}
                            />
                            {/* <div className = {styles.cls_overviewAppEditBtn}>
                                <div className = {styles.cls_overviewEdit} >Copy</div>
                            </div> */}
                        </FormControl>
                    </Box>
                </div>
                <div className = {styles.cls_overviewPart1}>
                    <div className = {styles.cls_overviewTitle}>Delete this App</div>
                    <div className = {styles.cls_overviewDeleteApp}>
                        <div className = {styles.cls_overViewWarningText}>If you delete this app, you’ll not be able to recover it</div>
                        <div className = {styles.cls_overviewBtnWrap}>
                            <div className = {styles.cls_overViewDeleteBtn} onClick = {() => handleAppDelete()}>
                                Delete App
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        {
            <Dialog open={editApp} onClose={handlePopupClose} aria-labelledby="form-dialog-title" className = "cls_editAppPopup" fullWidth>
                <DialogTitle id="form-dialog-title" className = {styles.cls_overviewTitle}>Edit App Details</DialogTitle>
                    <DialogContent>
                        <DialogContentText className = {styles.apiKeyText}>
                            Enter the details of the App
                        </DialogContentText>
                        <TextField  
                            value = {appName}
                            onChange = {(event) => changeAppName(event.target.value)}
                            autoFocus
                            color="secondary"
                            id="name"
                            className = {styles.cls_appDetinputBox}
                            label="name"
                            type="text"
                            fullWidth
                        />
                        {
                            appNameErr !== "" && 
                            <DialogContentText className = {styles.apiKeyTextError}>
                                {appNameErr}
                            </DialogContentText>
                        }
                        <TextField  
                            value = {appDesc}
                            onChange = {(event) => changeAppDesc(event.target.value)}
                            autoFocus
                            color="secondary"
                            id="description"
                            className = {styles.cls_appDetinputBox}
                            label="description"
                            type="text"
                            fullWidth
                        />
                        {
                            appDescErr !== "" && 
                            <DialogContentText className = {styles.apiKeyTextError}>
                                {appDescErr}
                            </DialogContentText>
                        }
                        <TextField  
                            value = {redirectUri}
                            onChange = {(event) => changeRedirectUri(event.target.value)}
                            autoFocus
                            color="secondary"
                            id="redirectUri"
                            className = {styles.cls_appDetinputBox}
                            label="Redirect Uri"
                            type="text"
                            fullWidth
                        />
                        {
                            appUriErr !== "" && 
                            <DialogContentText className = {styles.apiKeyTextError}>
                                {appUriErr}
                            </DialogContentText>
                        }
                    </DialogContent>
                    <DialogActions className = {styles.cls_apiBtnWrapper}>
                        <div className = {styles.cls_appCancelBtn} onClick={handlePopupClose} color="primary">
                            Cancel
                        </div>
                        <div className = {styles.cls_appCreateBtn} onClick={() => handleSave()}color="primary">
                            Save
                        </div>
                    </DialogActions>
            </Dialog>
        }
        {
            <Dialog open={editLogo} onClose={handlePopupClose} aria-labelledby="form-dialog-title" className = "cls_editAppPopup" fullWidth>
                <DialogTitle id="form-dialog-title" className = {styles.cls_overviewTitle}>Edit Logo</DialogTitle>
                    <DialogContent>
                        <DialogContentText className = {styles.apiKeyText}>
                            Upload the new Logo
                        </DialogContentText>
                        <img src={thumb}
                            className = {styles.userSetImg}
                            alt={"file"}
                            height={60}
                            width={60} 
                        />
                        <label className="custom-file-upload" style = {{display: "block", margin: "20px 0px"}}>
                            <input id="file" name="file" type="file" onChange={(event) => {
                                console.log(event.currentTarget.files[0], "event.currentTarget.files[0]");
                                onChangePicture(event)
                            }} className="form-control" />
                            <i className="fa fa-cloud-upload"></i> Change
                        </label>
                        {
                            fileImageErr !== "" && 
                            <DialogContentText className = {styles.apiKeyTextError}>
                                {fileImageErr}
                            </DialogContentText>
                        }
                    </DialogContent>
                    <DialogActions className = {styles.cls_apiBtnWrapper}>
                        <div className = {styles.cls_appCancelBtn} onClick={handlePopupClose} color="primary">
                            Cancel
                        </div>
                        <div className = {styles.cls_appCreateBtn} onClick={() => handleSaveLogo()}color="primary">
                            Save
                        </div>
                    </DialogActions>
            </Dialog>
        }
        </>
    )
}

export default Overview;

