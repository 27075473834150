import { navigate } from "gatsby";
import React, { useEffect, useState } from "react"; 
import { toast } from "react-toastify";
import { API_URL } from "../constants/constants";
import { requestHandler } from "../utils/utils";
const styles = require("../styles/common.module.css");

const Invite = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token") ? queryParams.get("token") : "";
    const [ data , setData ] = useState({
        "role": "", 
        "invited_by": "", 
        "team": ""
    }); 

    const declineBtnClick = async () => {
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `team/invite/reject?team_invite_token=${token}` , null , headers, "PUT")
        if(result.status === "success") 
        {
            toast.success(result.message);
            navigate("/");
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const acceptBtnClick = async () => {
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `team/invite/accept?team_invite_token=${token}` , null , headers, "PUT")
        if(result.status === "success") 
        {
            toast.success(result.message);
            navigate("/");
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const getInviteDetailsAPI = async () => {
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `team/invite?team_invite_token=${token}` , null , headers, "GET")
        if(result.status === "success") 
        {
            setData(result.data)
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    useEffect(() => {
        getInviteDetailsAPI();
    }, [token])

    return (
        <div className = "cls_InviteWrapper">
            <div className = {styles.cls_InvitePageWrapper}>
                <div className = {styles.cls_InvitePage}>
                    <div className = {styles.cls_InviteHeader}>
                        <div className = {styles.cls_InviteAppName}> {data.team} </div>
                        <div className = {styles.cls_InviteAppPositon}>{data.role}</div>
                    </div>
                    <div className = {styles.cls_InivteBody}>
                        <span>{data.invited_by}</span> invited you to collaborate 
                    </div>
                    <div className = {styles.cls_AcceptBtnWrapper}>
                        <div className = {styles.cls_DeclineBtn} onClick = {() => declineBtnClick()}>Decline</div>
                        <div className = {styles.cls_AccentBtn} onClick = {() => acceptBtnClick()}>Accept</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invite;