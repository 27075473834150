import React from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Dialog, DialogContent, DialogTitle ,DialogContentText, TextField, DialogActions} from "@material-ui/core";
import { useState } from "react";

const DeleteModal = ({ title , content, open , onDelete , children, handleClose} : any) => {

    const [ input, setInput ] = useState("");
    const [ inputEmpty , setEmpty ] = useState(false);

    const handleDelete = () => {
        if(input.toLowerCase() === "delete") {
            setEmpty(false);
            onDelete()
        }
        else if(input === "" )
            setEmpty(true);
        else {
            setEmpty(false);
            handleClose();
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className = "cls_apikeyPopup" fullWidth>
            <DialogTitle id="form-dialog-title" className = "cls_DeleteTitle">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText className = "deleteText">
                    {content}
                </DialogContentText>
                <DialogContentText className = "deleteText">
                    To confirm deletion, enter "Delete" in the field.
                </DialogContentText>
                <TextField  
                    value = {input}
                    onChange = {(event) => setInput(event.target.value)}
                    autoFocus
                    id="name"
                    color="secondary"
                    className = "cls_appDetinputBox"
                    label=""
                    type="text"
                    fullWidth
                />
                {
                    inputEmpty === true && 
                    <DialogContentText className = "apiKeyTextError">
                        Input Cannot be empty
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions className = "cls_apiBtnWrapper">
                <div className = "cls_appCancelBtn" onClick={handleClose} color="primary">
                    Cancel
                </div>
                <div className = "cls_appCreateBtn" onClick={() => handleDelete()} color="primary" data-disabled = {!(input.toLowerCase() === "delete")}>
                    Delete
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteModal;